import { isObject } from "@/lib/utils/isObject"
import Link from "next/link"
import GatingIcon from "./gating/icon"
import RotaryLogo from "./rotary-logo"
import SvgJsx from "./svg/svg-jsx"
import { hasData } from "@/lib/utils/hasData"
import PostMenuMobile from "../profile/post/post-menu-mobile"
import { Arrow } from "./arrow"

function Tabs({
     tabarray
    ,clickval
    ,set_clickval
    ,set_extra=(n)=>{}
    ,tab_style="underline"
    ,className="text-sm px-3 py-2"
    ,userdata={id:0}
}) {



    if (tab_style=="underline") {
        return (<>
            <ul
                    className='flex'
                >
                    {tabarray.map((e,index)=> {
        
                        var text
                        var val
        
                        if (isObject(e)) {
                            text= e?.text
                            val = e?.value
                        } else {
                            text = e;
                            val = e;
                        }
        
                        return (<li
                            key={index}
                            className={`px-0 py-2 border-b-2 sm:border-b-4 mr-4  cursor-pointer hover:border-gray-600   font-bold text-sm sm:text-lg
                            ${clickval == val ? 
                                `border-red-500 text-white ` 
                                : ' text-gray-200 border-transparent'}
                            `}
                            onClick={()=> {
                                set_clickval(val)
                            }}
                        ><div className="flex items-center content-center">
                            <div>{text}</div>
                            {e?.premium == "1" && 
                            <div  className="ml-1 text-red-600">
                                

                                <GatingIcon
                                    id={`premium-${val}`}
                                    tip={`Contains Contributor-Only Features`}
                                    classSize={`w-4 h-4`}
                                    placement={`bottom`}
                                />

                                    
                            </div>
                            }
                            
                        </div>
                            
                    </li>)
                    }
                    )}
                  
                </ul>
            </>)
    }


    if (tab_style=="linkpill") {
        return (
            <div className="flex-1 flex w-full">
                <ul
                        className='flex items-center content-center'
                    >
                        {tabarray.map((e,index)=> {
            
                            var text
                            var val
                            var url
                            var special = false
            
                            if (isObject(e)) {
                                text= e?.text
                                val = e?.value
                                url = e?.url
                                special = hasData(e?.special) ? e.special : false
                            } else {
                                text = e;
                                val = e;
                                url = e;
                            }
            
                            return (
                                <li
                                    key={index}
                                    className={` flex`}
                                    // onClick={()=> {
                                    //     set_clickval(val)
                                    // }}
                                >
                                    <Link
                                        href={`${url}`}
                                        className={` ${className}  ${special ? " border border-gray-700 mr-1 rounded-full hover:border-white" : ""} cursor-pointer hover:bg-gray-700   font-bold rounded-md flex items-center content-center mr-1
                                        ${clickval == val ? ` bg-gray-600 text-white ` : 'text-gray-200 bg-transparent'}
                                        `}>

                                        {special &&
                                        <span>
                                        <SvgJsx 
                                            type={'fill'}
                                            icon={"chevron-left-sm"}
                                            className={`h-5 w-5 -ml-2 `}
                                            title={text}
                                        />
                                        </span>
                                        }
                                        <span>{text}</span>

                                    </Link>
                            </li>
                            );
                        }
                        )}
                      
                    </ul>
                </div>
        );
    }

    


    if (tab_style=="linkpillclick") {
        return (<div className="flex-1 flex w-full">
            <ul
                    className='flex items-center content-center'
                >
                    {tabarray.map((e,index)=> {
        
                        var text
                        var val
                        var url
                        var special = false
        
                        if (isObject(e)) {
                            text= e?.text
                            val = e?.value
                            url = e?.url
                            special = hasData(e?.special) ? e.special : false
                        } else {
                            text = e;
                            val = e;
                            url = e;
                        }
        
                        return (<li
                            key={index}
                            className={` flex`}
                            onClick={()=> {
                                set_clickval(val)
                                // router.push({
                                //     pathname: `${e?.url}`,
                                //     query: { id: file_details?.upload_id?.toString()}
                                //   }, 
                                //   undefined, { shallow: true }
                                // )
                            }}
                        >
                            
                            <a
                                className={` ${className}  ${special ? " border mr-1 rounded-full" : ""} cursor-pointer hover:bg-gray-700   font-bold rounded-md flex items-center content-center
                                ${clickval == val ? `bbg-gray-600 text-white ` : 'text-gray-200 bg-transparent'}
                                `}
                            >
                            {special &&
                            <span>
                            <SvgJsx 
                                type={'fill'}
                                icon={"chevron-left-sm"}
                                className={`h-5 w-5 -ml-2 `}
                                title={text}
                            />
                            </span>
                            }
                            <span>{text}</span>
                            </a>
                            
                    </li>)
                    }
                    )}
                  
                </ul>
            </div>)
    }

    

    if (tab_style=="postshome") {
        return (<div className={`flex-1 flex w-full`}>
            <ul
                    className='flex items-center content-center'
                >
                    {tabarray.map((e,index)=> {
        
                        return (<li
                            key={index}
                            className={` flex ${userdata?.id == 0 && e?.permission == 1 ? "hidden" : ""}`}
                            onClick={()=> {
                                set_clickval(e?.value)
                                // router.push({
                                //     pathname: `${e?.url}`,
                                //     query: { id: file_details?.upload_id?.toString()}
                                //   }, 
                                //   undefined, { shallow: true }
                                // )
                            }}
                        >
                            
                            <a
                                className={` ${className}   cursor-pointer hover:bg-gray-700  font-bold rounded-md flex items-center content-center
                                ${clickval == e?.value ? `bg-gray-600 text-white ` : 'text-gray-200 bg-transparent'}
                                `}
                            >
                          
                            <span>{e?.text}</span>
                            </a>
                            
                    </li>)
                    }
                    )}
                  
                </ul>
            </div>)
    }


    if (tab_style=="pill") {
        return (<div className="flex-1 flex w-full">
            <ul
                    className='flex items-center content-center'
                >
                    {tabarray.map((e,index)=> {
        
                        var text
                        var val
                        var url
        
                        if (isObject(e)) {
                            text= e?.text
                            val = e?.value
                            url = e?.url
                        } else {
                            text = e;
                            val = e;
                            url = e;
                        }
        
                        return (<li
                            key={index}
                            className={` flex`}
                            // onClick={()=> {
                            //     set_clickval(val)
                            // }}
                        >
                           
                            <div
                                className={` ${className}   cursor-pointer hover:bg-gray-700   font-bold rounded-md
                                ${clickval == val ? `bg-gray-600 text-white ` : 'text-gray-200 bg-transparent'}
                                `}
                                onClick={()=> {
                                    set_clickval(val)
                                    set_extra(val)
                                }}
                            >
                            {text}
                            </div>
                    </li>)
                    }
                    )}
                  
                </ul>
            </div>)
    }


    if (tab_style == "pillmobile") {
        return (<>
                <PostMenuMobile 
                    tabarray={tabarray}
                    clickval={clickval}
                    set_clickval={set_clickval}
                />
        </>)
    }

    if (tab_style == "selectbox") {

    return (<>
        <div
                className='flex'
            >
                {tabarray.map((e,index)=> {
    
                    var text
                    var val
    
                    if (isObject(e)) {
                        text= e?.text
                        val = e?.value
                    } else {
                        text = e;
                        val = e;
                    }
    
                    return (<div
                        key={index}
                        className={`${className} cursor-pointer hover:bg-gray-700   font-bold

                          border-gray-700 border-y border-r
                        ${index == 0 ? 'rounded-l-md border-l' : ''}
                        ${index == tabarray.length-1 ? 'rounded-r-md' : ''}

                        ${clickval == val 
                                    ? `bg-gray-600 text-white` 
                                    : ' text-gray-200 bg-transparent  '}
                        `}
                        onClick={()=> {
                            set_clickval(val);
                            // router.push(`/${}`, undefined, { shallow: true });
                        }}
                    >
                        {text}
                </div>)
                }
                )}
              
            </div>
        </>)

    }

    if (tab_style == "selectboxhome") {

        let hometabarray = tabarray?.filter(e=>(e?.permission !== 1 || (e?.permission == 1 && userdata?.id > 0)));

        return (<>
            <div
                    className='flex'
                >
                    {hometabarray?.map((e,index)=> {
        
                        var text =e?.text
                        var val = e?.value
                        var url = e?.url
                        var icon = e?.icon
                        var permission = e?.permission
                        var submenu = e?.submenu
        
                        return (<div
                            key={index}
                            className={`${className} relative flex items-center content-center cursor-pointer hover:bg-gray-700   font-bold
                                whitespace-nowrap
                             border-gray-700 border-y border-r
                            ${index == 0 ? 'rounded-l-md border-l' : ''}
                            ${index == (hometabarray.length-1) ? 'rounded-r-md' : ''}
    
                            ${clickval == val 
                                        ? `bg-gray-600 text-white ` 
                                        : 'text-gray-200 bg-transparent  '}
                            `}
                            onClick={()=> {
                                set_clickval(val,url);
                            }}
                        >
                            <div className="sm:hidden block whitespace-nowrap">{icon}</div>
                            <div className="sm:block hidden whitespace-nowrap">{text}</div>
                            {(submenu?.toString()=="1" && clickval == val) &&
                            <div className="absolute mt-[26px] text-center left-0 right-0">
                                <div className="relative w-full mx-auto left-0 right-0">
                                    <div className="absolute left-0 right-0">
                                        <Arrow
                                            color={'rgb(55 65 81)'} //show_options ? `#9CA3AF` : `#6B7280`}
                                            direction={`up`}
                                            size={10}
                                            className={``}
                                        />
                                    </div>
                                    <div className="absolute left-0 right-0 mt-[2.5px]">
                                        <Arrow
                                            color={'rgb(17 24 39)'} //show_options ? `#9CA3AF` : `#6B7280`}
                                            direction={`up`}
                                            size={10}
                                            className={``}
                                        />
                                    </div>
                                </div>
                            </div>
                            }
                    </div>)
                    }
                    )}
                  
                </div>
            </>)
    
        }


    return (<></>)
    
}

export default Tabs


